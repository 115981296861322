<template>
  <div class="badge"
       :style="{background: params.colors.background}">
    <div class="badge__wrapper"
         :style="{color: params.colors.text}">
      {{params.text}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    params: {},
  },
};
</script>
